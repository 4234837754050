import React from "react";
import { Link } from 'react-router-dom';
import './landing.css'

function Landing() {
    return (
        <div className="wrapper">
            <div>
                <h1>OnTheGrind</h1>
            </div>
            <div className="navigation-section">
                <div className="link"><Link className={"link-styles"} to="/curators">Apply</Link> to the playlist curator network</div>
                {/* <div className="link"><Link className={"link-styles"} to="/artists">Join</Link> the artist waitlist</div> */}
            </div>
        </div>
    );
}

export default Landing;
