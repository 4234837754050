import React, { useState } from "react";
import './curators.css'

const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
};

const validateLink = (link) => {
    const linkRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,6})(\/[\w.-]*)*\/?$/i;
    return linkRegex.test(link);
};

function Curators() {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [playlistLink, setPlaylistLink] = useState("")
    const [genre, setGenre] = useState("")
    const [message, setMessage] = useState("")
    const submitMessage = "Submitted!"
    const softRed = "rgb(245, 61, 92)"
    const softGreen = "rgb(83, 212, 128)"

    const sendDataToGoogleSheet = async () => {
        if (name === "" || email === "" || playlistLink === "" || genre === "") {
            setMessage("Fill out all fields!")
            return
        }

        if (!validateEmail(email)) {
            setMessage("Email invalid!")
            return
        }

        // if (!validateLink(playlistLink)) {
        //     setMessage("Playlist link invalid!")
        //     return
        // }

        setMessage("Submitting...")


        const data = {
            name: name,
            email: email,
            playlistLink: playlistLink,
            genre: genre
        };

        try {
            const response = await fetch("https://script.google.com/macros/s/AKfycbxTaAbhemCjxPGmRGo05pgVwHMXO3OLiGjrdu5IH0shOskRugJJBI958RiCa1Nv2gWC/exec", {
            method: "POST",
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
            });
            if (response.ok) {
            console.log("Data sent successfully!");
            } else {
            console.error("Error sending data");
            }
        } catch (error) {
            console.error("Error:", error);
        }

        setName("")
        setEmail("")
        setPlaylistLink("")
        setGenre("")
        setMessage(submitMessage)
    };

    return (
        <div className="wrapper">
            <h1>Apply to join our playlist curator network!</h1>
            <h3>*The number of playlist saves doesn't matter*</h3>
            <h3>*Earn rewards for accepting songs*</h3>
            <div className="form">
                <div className="innerform">
                    <label htmlFor="full name">Full Name*</label>
                    <input placeholder="Travis Scott" type="text" name="full name" value={name} onChange={(e) => {setName(e.target.value)}}/>
                    <label htmlFor="email">Email*</label>
                    <input placeholder="travisscott@gmail.com" type="email" name="email" value={email} onChange={(e) => {setEmail(e.target.value)}}/>
                    <label htmlFor="playlist link">Playlist Link*</label>
                    <input placeholder="https://open.spotify.com/playlist/152WyPcnxD9AmqUfhQ03D7?si=wpTOI0TIRVib6MrZH0lkxw&pi=u-9B593VX8SviX&fbclid=PAZXh0bgNhZW0CMTEAAaYTbo8kTs0FWolH97rKEaxXLf-kH3y3dI3m-6ix7qjDydFZdzmHBAFY2BQ_aem_wIzFBNuD2hU980Xik6xMBQ&nd=1&dlsi=345ee40520d94687" type="text" name="playlist link" value={playlistLink} onChange={(e) => {setPlaylistLink(e.target.value)}}/>
                    <label htmlFor="genre">Playlist Genre*</label>
                    <input placeholder="Melodic Rap" type="text" name="genre" value={genre} onChange={(e) => {setGenre(e.target.value)}}/>
                </div>
            </div>
            <button className="submit" onClick={sendDataToGoogleSheet}>Submit</button>
            <div style={{"color": message == submitMessage ? softGreen : softRed}} className="message">{message}</div>
        </div>
    );
}
  
export default Curators;
  