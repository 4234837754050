import './App.css';
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Artists from './Artists';
import Curators from './Curators';
import Landing from './Landing';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing/>,
  },
  {
    path: "/artists",
    element: <Artists/>
  },
  {
    path: "/curators",
    element: <Curators/>
  }
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
