import React from "react";

function Artists() {
    return (
        <div>
            artists
        </div>
    );
}

export default Artists;
